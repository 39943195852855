import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { gap } from "react-app/src/components/Common/util";
import { MQ } from "react-app/src/styles/helpers";
import spaces from "react-app/src/styles/theme/spaces";

export const MediumBannerWrapper = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: ${gap.mobile};

  ${MQ("laptop")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: ${gap.laptop};
  }
`;

export const SmallBannerWrapper = styled.div(css`
  overflow: hidden;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  gap: ${gap.mobile};

  ${MQ("laptop")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: ${gap.laptop};
  }
`);

export const ExtraSmallBannerWrapper = styled.div(css`
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: ${gap.mobile};

  ${MQ("laptop")} {
    grid-template-columns: repeat(4, 1fr);
    gap: ${gap.laptop};
  }
`);

export const DisclaimerInformationWrapper = styled.div`
  position: absolute;
  left: ${spaces.smallRegular};
  top: ${spaces.regular};
  z-index: 2;

  ${MQ("laptop")} {
    left: ${spaces.large};
    top: ${spaces.large};
  }
`;
